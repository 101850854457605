import React from "react";
import styled from "styled-components";
import { Heading, Label } from "./Typography.js";
import { Box } from "./Box";
import Image from "./Image";

const DropImgWrapper = styled.div`
    width: 190px;
    z-index:1;
    height: 190px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    border-bottom-left-radius: ${(props) => (props.drop === "bottom-left" ? "0%" : "50%")};
    border-top-left-radius: ${(props) => (props.drop === "top-left" ? "0%" : "50%")};
    border-bottom-right-radius: ${(props) => (props.drop === "bottom-right" ? "0%" : "50%")};
    border-top-right-radius: ${(props) => (props.drop === "top-right" ? "0%" : "50%")};
`;

const DropImgMask = styled.div`
    width: 100%;
    height: 100%;
    transition: ease-in-out 500ms;
    &:hover {
        transform: scale(1.06);
    }
`;

const TeamElement = ({ name, imagesrc, role, drop, founder }) => {
    return (
        <Box
            className={`align-items-center`}
            round={"md"}
            pad={"sm"}
            background={"aquamarine"}
            style={{ padding: '16px 0', zIndex:2 }}
        >
            <DropImgWrapper className={drop} drop={drop}>
                <DropImgMask>
                    <Image filename={imagesrc} alt={name}/>
                </DropImgMask>
            </DropImgWrapper>
            <Heading level={"4"} margin={"15px 0 0"}>
                {name}
            </Heading>
            <Label customColor='blueDarken100' fontWeight="300" fontSize="60%">
                {" "}
                {founder && <><span> CO-FOUNDER  </span><br /></>}
                {role}
            </Label>
        </Box>
    );
};

export default TeamElement;
