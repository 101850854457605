import React, {useEffect, useRef} from "react";
import styled, {ThemeProvider} from 'styled-components';
import withLayout from "../layout";
import {Heading} from "../components/UI/Typography";
import {Button} from "../components/UI/Button";
import TeamElement from "../components/UI/TeamElement";
import dark from "../theme/dark";
import {FormattedMessage, injectIntl} from "react-intl";
import TeamPhoto from '../images/agency/team.jpg'
import useDimensions from "react-use-dimensions";
import ImageCarousel from "../components/Carousels/ImageCarousel";
import srcVideo from "../images/team_hero_video.mp4";
import "../animations/agency/agencyAnimation.scss";
import SEO from "../components/SEO";
import Hero from "../components/Hero";
//images
import weAre from '../images/agency/wearekromin.svg'
import vision from '../images/agency/vision.svg'
import mission from '../images/agency/mission.svg'
import {agencyAnimationsHub} from "../animations/agency";
import useWindowSize from "../hooks/useWindowSize";
import FullWidthMessage from "../components/FullWidthMessage";

const OfficeImages = [
    'our_office/gallery_1.jpg', 'our_office/gallery_2.jpg', 'our_office/gallery_3.jpg', 'our_office/gallery_4.jpg',
    'our_office/gallery_5.jpg', 'our_office/gallery_6.jpg', 'our_office/gallery_7.jpg', 'our_office/gallery_8.jpg',
    'our_office/gallery_9.jpg', 'our_office/gallery_10.jpg', 'our_office/gallery_11.jpg', 'our_office/gallery_12.jpg',
    'our_office/gallery_13.jpg', 'our_office/gallery_14.jpg', 'our_office/gallery_15.jpg', 'our_office/gallery_16.jpg',
    'our_office/gallery_17.jpg', 'our_office/gallery_18.jpg', 'our_office/gallery_19.jpg', 'our_office/gallery_20.jpg',
    'our_office/gallery_21.jpg', 'our_office/gallery_22.jpg'
]

const teamGridRows = [
    [
        {name: "Emanuele Maria Esposito", image: "emanuele_esposito.jpg", role: "HEAD OF MARKETING", link: "https://www.linkedin.com/in/espositoemanuele/"},
        {name: "Marco Ciotoli", image: "marco_ciotoli.jpg", role: "HEAD OF FRONT-END DEV", link: "https://www.linkedin.com/in/marcociotoli/"},
        {name: "Vincenzo Ruffa", image: "vincenzo_ruffa.jpg", role: "HEAD OF BACK-END DEV", link: "https://www.linkedin.com/in/vruffa/"},
    ],
    [
        {name: "Cesidio Borrelli", image: "cesidio_borrelli.jpeg", role: "COMMERCIAL SALES REPRESENTATIVE", link: "https://www.linkedin.com/in/cesidio-borrelli/"},
        {name: "Silvia Staccone", image: "silvia_staccone.jpg", role: "HEAD OF ACCOUNTING", link: "https://www.linkedin.com/in/silvia-staccone-b301b115a/"},
        {name: "Pasquale Gallo", image: "pasquale_gallo.jpg", role: "PROJECT MANAGER | COPYWRITER", link: "https://www.linkedin.com/in/pasquale-gallo-03b477102/"},
    ],
    [
        {name: "Alessia Ceci", image: "alessia_ceci.jpg", role: "COPYWRITER | CONTENT & COMMUNITY MANAGER"},
        {name: "Sara Mastrantonio", image: "sara_mastrantonio.jpg", role: "HEAD OF DESIGN", link: "https://www.linkedin.com/in/sara-mastrantonio-34963a130/"},
        {name: "Giuseppe Russo", image: "giuseppe_russo.jpg", role: "DESIGNER", link: "https://www.linkedin.com/in/giuseppe-russo-6a7b39147/"},
        {name: "Stefano Carlini", image: "stefano_carlini.jpeg", role: "JUNIOR UX/UI DESIGNER", link: "https://www.linkedin.com/in/stefano-carlini-3035b9204/"},
    ],
    [
        {name: "Lorenzo Promutico", image: "lorenzo_promutico.jpg", role: "CREATIVE DIRECTOR", link: "https://www.linkedin.com/in/lorenzo-promutico-324440198/"},
        {name: "Greta Testugini", image: "greta_testugini.jpg", role: "SOCIAL MEDIA MANAGER", link: "https://www.linkedin.com/in/greta-testugini-995a161b5/"},
        {name: "Giacomo Caruzzi", image: "giacomo_caruzzi.jpg", role: "ART DIRECTOR"},
        {name: "Alessandro Pietrobono", image: "alessandro_pietrobono.jpg", role: "VIDEO EDITOR & MOTION DESIGNER", link: "https://www.linkedin.com/in/alessandro-pietrobono-948822212/"},
    ],
    [
        {name: "Riccardo Schietroma", image: "riccardo_schietroma.jpeg", role: "CREATIVE EXECUTIVE"},
        {name: "Stefan Ratanu", image: "stefan_ratanu.jpeg", role: "GRAPHIC DESIGNER", link: "https://www.linkedin.com/in/stefan-ratanu-603435222/"},
        {name: "Cristian Ciotoli", image: "cristian_ciotoli.jpg", role: "E-COMMERCE SPECIALIST"},
        {name: "Carlo Angeletti", image: "carlo_angeletti.jpg", role: "IT & WEB DEVELOPER", link: "https://www.linkedin.com/in/carlo-angeletti/"},
    ],
    [
        {name: "Manuel Fontana", image: "manuel_fontana.jpg", role: "FULL STACK DEV", link: "https://www.linkedin.com/in/manuel-fontana-3152b719b/"},
        {name: "Matteo Pulcrano", image: "matteo_pulcrano.jpg", role: "SENIOR BACK-END DEV", link: "https://www.linkedin.com/in/matteo-pulcrano-88b57a152/"},
        {name: "Cristian Belli", image: "cristian_belli.jpg", role: "BACK-END DEV", link: "https://www.linkedin.com/in/cristian-belli-940a481b5/"},
        {name: "Elia De Santis", image: "agency/elia_de_santis.jpg", role: "BACK-END DEV", link: "https://www.linkedin.com/in/elia-de-santis-085a2720a/"},
    ],
    [
        {name: "Luca Denni", image: "luca_denni.jpg", role: "BACK-END DEV"},
        {name: "Francesco Monti", image: "francesco_monti.jpg", role: "SENIOR FRONT-END DEV", link: "https://www.linkedin.com/in/francesco-monti-9b2377127/"},
        {name: "Emanuele Padovani", image: "emanuele_padovani.jpeg", role: "FRONT-END DEV", link: "https://www.linkedin.com/in/emanuele-padovani"},
        {name: "Gianmarco Ferretti", image: "gianmarco_ferretti.jpeg", role: "FRONT-END DEV"},
    ],
    [
        {name: "Alessia Patrizi", image: "alessia_patrizi.jpg", role: "FRONT-END DEV"},
        {name: "Antonio Del Maestro", image: "antonio_del_maestro.jpg", role: "DATA ANALYST"},
        {name: "", image: "plus_25.png"}
    ]
]

const MeetTheTeam = styled.div`
    background-image:url(${TeamPhoto});
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        height: 400px;
    }
`;


const AgencyPage = ({intl}) => {
    const [ref, refSize] = useDimensions();
    const keywords = intl.formatMessage({id: 'agency.meta_keywords'}).split(',') || [];
    const {width} = useWindowSize();
    const isMobile = width < 480;
    //gsap refs
    const weAreRef = useRef();
    const weAreRefScrollContainer = useRef();
    const visionRef = useRef();
    const missionRef = useRef();
    const scrollRef = useRef(null);

    //GSAP call wait until DOM has been rendered
    useEffect(() => {
        agencyAnimationsHub(missionRef, visionRef, weAreRef, weAreRefScrollContainer, scrollRef, isMobile)
    }, []);

    return (
        <div ref={scrollRef}>
            <SEO
                title={intl.formatMessage({id: 'agency.meta_title'})}
                description={intl.formatMessage({id: 'agency.meta_description'})}
                keywords={keywords}
                lang={intl.locale}
            />
            <ThemeProvider
                theme={dark}
                colors={dark ? dark.colors : null}
            >
                <Hero
                    isVideo={true}
                    title={"agency.title"}
                    subtitle={"agency.title_thin"}
                    paragraph={"agency.subtitle"}
                    thinChild={"3"}
                    srcVideo={srcVideo}
                />
            </ThemeProvider>

            <div className="big-section-padding-top">
                <div className="container">
                    <img
                        src={mission}
                        alt='Mission'
                        id="mission-img"
                        ref={missionRef}
                    />
                    <div className="row mt-lg-5 mb-lg-5 mt-5 mb-5 equal">
                        <div className="col-md-8 col-12">
                            <p className="coreInfo">
                                <FormattedMessage id={"agency.mission_text"}/>
                            </p>
                        </div>
                    </div>
                    <img
                        src={vision}
                        alt='Vision'
                        id="vision-img"
                        ref={visionRef}
                    />
                    <div className="row mt-lg-5 mb-lg-5 mt-5 mb-5 justify-content-center equal">
                        <div className="col-md-4 d-md-block d-none"/>
                        <div className="col-md-8 col-12 align-items-center text-right">
                            <p className="coreInfo">
                                <FormattedMessage id={"agency.vision_text"}/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={ref}>
                <div className="container" ref={weAreRefScrollContainer}>
                    <img
                        src={weAre}
                        alt='wearekromin'
                        id="weAre"
                        ref={weAreRef}
                    />
                    {/* TEAM PHOTOS */}
                    {teamGridRows.map((row, rowIndex) => {
                        const isFirstRow = rowIndex === 0
                        const isLastRow = rowIndex === (teamGridRows.length - 1)

                        return (
                            <div
                                className={
                                    isFirstRow
                                        ? "row my-5 justify-content-center equal"
                                        : isLastRow
                                            ? "row my-lg-5 my-0 justify-content-around section-padding-bottom"
                                            : "row my-lg-5 my-0 justify-content-center equal"
                                    }
                                key={rowIndex}
                            >
                                {row.map((teamElement, teamElementindex) => {
                                    const isDrop25 = (isLastRow && (teamElementindex === (row.length - 1)))
                                    const dropDirection =
                                        ((rowIndex % 2 === 0 && teamElementindex % 2 !== 0) || isDrop25)
                                        || (rowIndex % 2 !== 0 && teamElementindex % 2 === 0)
                                            ? "bottom-right"
                                            : "top-right"

                                    return (
                                        <div
                                            className="col align-items-center text-center"
                                            key={teamElementindex}
                                        >
                                            {
                                                !!teamElement.link
                                                    ? (
                                                        <a
                                                            className="link-no-decoration-team"
                                                            href={teamElement.link}
                                                            target="_blank"
                                                            rel="noopener norefferer"
                                                        >
                                                            <TeamElement
                                                                drop={dropDirection}
                                                                name={teamElement.name}
                                                                imagesrc={teamElement.image}
                                                                role={teamElement.role}
                                                                founder={isFirstRow}
                                                            />
                                                        </a>
                                                    )
                                                    : (
                                                        <TeamElement
                                                            drop={dropDirection}
                                                            name={teamElement.name}
                                                            imagesrc={teamElement.image}
                                                            role={teamElement.role}
                                                            founder={isFirstRow}
                                                        />
                                                    )
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>

            <ImageCarousel images={OfficeImages} dots={4}/>

            <FullWidthMessage isLight={true} color={'white'} title={"agency.work_with_us_title"} text1={"agency.work_with_us_text"} intl={intl}
                              ctaLink={"/jobs/"} ctaText={refSize.width > 764 ? "agency.see_open_positions_btn" : "agency.contact_us"}/>
            <ThemeProvider theme={dark} colors={dark ? dark.colors : null}>
                <MeetTheTeam>
                    <div className="d-flex flex-column align-items-center justify-content-center text-center">
                        <Heading level={"1"} bold={false} thinChild={1}>
                            <FormattedMessage id={"agency.meet_team_thin"}/> <br/>
                            <FormattedMessage id={"agency.meet_team"}/>
                        </Heading>
                        <a className="link-no-decoration-team" href={"https://www.instagram.com/wearekromin/"} target={'_blank'}>
                            <Button arrow label={intl.formatMessage({id: 'agency.follow_us'})} type={'primary'}/>
                        </a>
                    </div>
                </MeetTheTeam>
            </ThemeProvider>
        </div>
    );
};
const customProps = {
    localeKey: "team", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(AgencyPage));
