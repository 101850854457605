import React from "react"
import "react-multi-carousel/lib/styles.css"
import Carousel from "react-multi-carousel"
import Image from "../UI/Image"

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 4,
        paritialVisibilityGutter: 150
    },
    tablet: {
        breakpoint: { max: 767, min: 576 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 575, min: 0 },
        items: 1,
        paritialVisibilityGutter: 0
    }
};

const CustomDot = ({onClick, active }) => {
    return (
        <button
            onClick={() => onClick()}
            style={{
                cursor: 'pointer',
                background: active ? "linear-gradient(203.36deg, #B84FF8 12.03%, #8227EE 83.83%)" : "#E0E0E0",
                border: 'none',
                width: 12,
                height: 12,
                borderRadius: '50%',
                marginRight: 6,
                padding: 0
            }}
        />
    )
}

const ImageCarousel = ({images}) => {
    return (
        <Carousel
            ssr
            draggable
            partialVisbile={false}
            showDots={false}
            responsive={responsive}
            swipeable
            infinite
            autoPlay
            autoPlaySpeed={8000}
            shouldResetAutoplay
            customDot={<CustomDot/>}
            containerClass="image-carousel"
        >
            {images?.map((item, index) => (
                <div
                    className="image-container mt-3"
                    key={index}
                >
                    <Image
                        filename={item}
                        key={index}
                        alt="our method"
                        style={{
                            width: '100%',
                            pointerEvents: 'none',
                            userSelect: 'none'
                        }}
                    />
                </div>
            ))}
        </Carousel>
    )
}


export default ImageCarousel
