import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
/***
 *
 *********** Agency animations HUB ***********
 *
 ***/

const missionAnimation = (missionRef,scrollRef,isMobile) => {
    gsap.fromTo(
        missionRef.current,
        {
            x: isMobile ? -40 : -250,
        },
        {
            x: 100,
            scrollTrigger: {
                trigger: scrollRef?.current,
                start: "top top",
                end: "bottom bottom",
                scrub: true
            },
        }
    );
}

const visionAnimation = (visionRef,scrollRef,isMobile) => {
    gsap.fromTo(
        visionRef.current,
        {
            x: isMobile ? 30 : 350,
        },
        {
            x: isMobile ? -30 : 0,
            scrollTrigger: {
                trigger: scrollRef?.current,
                start: "top top",
                end: "bottom bottom",
                scrub: true
            },
        }
    );
}

const weAreAnimation = (weAreRef,scrollRef,isMobile) => {
    gsap.fromTo(
        weAreRef.current,
        {
            bottom: isMobile ? -100 : -140,
            opacity: 1
        },
        {
            bottom: isMobile ? 200 : 80,
            ease: "none",
            opacity: 0,
            scrollTrigger: {
                trigger: scrollRef?.current,
                start: "top top",
                end: "1200px bottom",
                scrub: true,
            },
        }
    );
}

export const agencyAnimationsHub = (missionRef, visionRef, weAreRef,weAreScrollRefContainer,scrollRef, isMobile) => {
    gsap.registerPlugin(ScrollTrigger);
    missionAnimation(missionRef,scrollRef,isMobile)
    visionAnimation(visionRef,scrollRef,isMobile)
    weAreAnimation(weAreRef,weAreScrollRefContainer,isMobile)
}
